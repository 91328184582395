<template>
  <section>
    <div class="w-full">
      <DataTable class="p-datatable-sm" scrollable scrollHeight="60vh" showGridlines :value="contratosStore.zonificaciones" tableStyle="min-width: 50rem">
        <Column field="departamento" style="width: 11rem;" header="Departamento/Municipios">
          <template #body="slotProps">
            <div class="flex gap-1 items-center">
              <div :class="slotProps.data.municipios.length ? 'mt-5' : ''" class="w-full">
                <Dropdown autoFilterFocus v-model="slotProps.data.departamento" filter :options="contratosStore._listado_departamentos" optionLabel="nomDpto" placeholder="Seleccione" panel-class="text-xs" class="w-full text-xs" />
              </div>
              <div class="flex gap-2">
                <Button v-if="!slotProps.data.municipios.length" v-tooltip="'Agregar municipios'" @click="abrirModalMunicipios(slotProps.data)" size="small" class="w-min" icon="pi pi-plus" />
                <Dialog v-model:visible="slotProps.data.display_modal" modal :style="{ width: '50rem', height: '46.1rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                  <template #header>
                    <div class="inline-flex align-items-center justify-content-center gap-2">
                      Grupo <span class="font-bold"># {{ slotProps.data.id }}</span> del departamento <span class="font-bold">{{ slotProps.data.departamento.name }}</span>
                    </div>
                  </template>
                  <div class="flex gap-2 justify-between" style="height: 39rem;">
                    <div class="h-full" style="width: 48%; height: 37rem;">
                      <div class="w-full flex gap-2 items-center justify-between mb-2">
                        <p class="font-medium mb-2">Listado de municipios</p>
                        <span class="p-input-icon-left w-42">
                          <i class="pi pi-search" />
                          <InputText v-model="municipioBusqueda" @input="filtrarMunicipios(slotProps.data, $event.target.value)" class="w-32" placeholder="Buscar" />
                        </span>
                      </div>
                      <div v-if="municipiosFiltrados.length" class="w-full flex justify-end px-4">
                        <button @click="agregarTodosMunicipios(slotProps.data), filtrarMunicipios(slotProps.data)" v-tooltip="'Agregar todos'">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-green-800">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                        </button>
                      </div>
                      <div style="height: 35rem" class="overflow-y-auto pr-2 w-full">
                        <div v-for="municipio in municipiosFiltrados" :key="municipio.cod_dane">
                          <div class="p-2 border my-1 flex justify-between">
                            <p class="text-xs">
                              {{ municipio.nomMpio }}
                            </p>
                            <button @click="slotProps.data.municipios.push(municipio), municipioBusqueda = '', filtrarMunicipios(slotProps.data)"><i class="pi pi-plus bg-green-600 text-white text-xs items-center justify-center flex rounded-full w-5 h-5"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="height: 40rem;" class="border"></div>
                    <div  style="width: 45%;  height: 37rem;">
                      <div class="w-full flex gap-2 items-center justify-between mb-2">
                        <p class="font-medium mb-2">Municipios seleccionados</p>
                        <span class="p-input-icon-left w-42">
                          <i class="pi pi-search" />
                          <InputText  v-model="municipioIngresadoBusqueda" @input="filtrarMunicipios(slotProps.data, $event.target.value, true)" class="w-32" placeholder="Buscar" />
                        </span>
                      </div>
                      <div v-if="slotProps.data.municipios.length" class="w-full flex justify-end px-4">
                        <button @click="slotProps.data.municipios = [], filtrarMunicipios(slotProps.data)" v-tooltip="'Agregar todos'">
                          <i class="pi pi-trash text-red-600"></i>
                        </button>
                      </div>
                      <div style="height: 35rem" class="overflow-y-auto pr-2 w-full">
                        <div v-for="municipio in municipioIngresadoBusqueda ? slotProps.data.municipios_temp : slotProps.data.municipios" :key="municipio.cod_dane">
                          <div class="p-2 border my-1 flex justify-between">
                            <p class="text-xs">
                              {{ municipio.nomMpio }}
                            </p>
                            <button @click="eliminarMunicipio(slotProps.data.municipios, municipio.CodDane), filtrarMunicipios(slotProps.data), municipioIngresadoBusqueda = ''"><i class="pi pi-times bg-red-600 text-white text-xs items-center justify-center flex rounded-full w-5 h-5"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
            <button v-if="slotProps.data.municipios.length" @click="abrirModalMunicipios(slotProps.data)" class="text-xs mt-1 text-blue-600 flex items-center gap-2">Municipios seleccionados <i class="pi pi-external-link text-xs"></i></button>
          </template>
        </Column>
        <Column field="fecha_inicio" class="w-14" header="Fecha inicio">
          <template #body="slotProps">
            <input type="date" class="w-full p-1 border-input rounded-md" :max="slotProps.data.fecha_fin" v-model="slotProps.data.fecha_inicio">
          </template>
        </Column>
        <Column field="fecha_fin" class="w-14" header="Fecha fin">
          <template #body="slotProps">
            <input type="date" class="w-full p-1 border-input rounded-md" :min="slotProps.data.fecha_inicio" v-model="slotProps.data.fecha_fin">
          </template>
        </Column>
        <Column field="regimenes" style="width: 8rem;" header="Régimen/Movilidad">
          <template #body="slotProps">
            <div class="grid grid-cols-2">
              <div v-for="regimen of contratosStore._listado_regimenes" :key="regimen" class="flex align-items-center text-xs p-1 gap-1">
                <Checkbox v-model="slotProps.data.regimenes" :inputId="regimen" name="regimen" :value="regimen" />
                <label class="self-center" :for="regimen">{{ regimen }}</label>
              </div>
            </div>
          </template>
        </Column>
        <Column v-if="contratosStore._modalidad_contrato_seleccionada === '01'" field="valor_maximo" class="w-14" header="Valor máximo">
          <template #body="slotProps">
            <InputNumber v-model="slotProps.data.valor_maximo" inputId="currency-us" mode="currency" currency="COP" locale="es-CO" />
          </template>
        </Column>
        <Column field="id_grupo" class="w-5" header="">
          <template #body="slotProps">
            <button @click="eliminarLinea(slotProps.data.id, slotProps.data.id_grupo)"><i class="pi pi-trash text-red-600"></i></button>
          </template>
        </Column>
      </DataTable>
      <div style="max-height: 70vh;" class="overflow-y-auto">
      </div>
      <div class="w-full flex justify-end mt-4">
        <Button @click="agregarLinea()" v-tooltip.left="'Agregar linea'" size="small" icon="pi pi-plus" />
      </div>
      <!-- <button @click="agregarLinea()" class="w-full flex items-center mt-2">
        <i class="pi pi-plus rounded-full text-gray-600 border-2 border-gray-600 text-xs w-5 h-5 items-center justify-center flex"></i>
        <div style="height: 0.05rem;" class="border-2 bg-gray-600 border-gray-600 w-full"></div>
      </button> -->
    </div>
  </section>
</template>
<script>
  import { onMounted, ref } from 'vue'
  import { useContratosStore } from '../../../stores/contratos.store'
  export default {
    name: 'configuracionContratosCrearZonificacion',
    components: {
    },
    setup () {
      // Stores
      const contratosStore = useContratosStore()
      // References
      const displayModalAggMunicipios = ref(false)
      const municipiosFiltrados = ref([])
      const municipioBusqueda = ref()
      const municipioIngresadoBusqueda = ref()
      // Methods
      const buscarZonificacion = (value) => {
        contratosStore.buscarZonificacion(value)
      }
      const agregarLinea = () => {
        let fechaInicio = ''
        let fechaFin = ''
        if (contratosStore.zonificaciones.length) {
          if (contratosStore.zonificaciones.some(a => a.fecha_inicio)) {
            fechaInicio = contratosStore.zonificaciones.find(a => a.fecha_inicio).fecha_inicio
          }
          if (contratosStore.zonificaciones.some(a => a.fecha_fin)) {
            fechaFin = contratosStore.zonificaciones.find(a => a.fecha_fin).fecha_fin
          }
        }
        contratosStore.zonificaciones.push({
          departamento: 0,
          id: contratosStore.zonificaciones.length + 1,
          cod_dane: 0,
          fecha_inicio: fechaInicio,
          fecha_fin: fechaFin,
          regimenes: [],
          valor_maximo: 0,
          municipios: [],
          display_modal: false
        })
      }
      const eliminarLinea = (id) => {
        const idx = contratosStore.zonificaciones.findIndex(a => a.id === id)
        contratosStore.zonificaciones.splice(idx, 1)
        contratosStore.zonificaciones.map((data, key) => {
          data.id = key + 1
        })
      }
      const buscarMunicipios = (event) => {
        contratosStore.buscarMunicipios(event.query)
      }
      const eliminarMunicipio = (municipios, idMpio) => {
        const idx = municipios.findIndex(a => a.CodDane === idMpio)
        municipios.splice(idx, 1)
      }
      const abrirModalMunicipios = (linea) => {
        linea.display_modal = true
        contratosStore.obtenerMunicipios({ codDpto: linea.departamento.codDpto }, '').then((data) => {
          municipiosFiltrados.value = []
          const municipiosOcupados = []
          contratosStore.zonificaciones.filter(a => a.departamento.codDpto === linea.departamento.codDpto).map(a => {
            if (a.municipios.length) {
              municipiosOcupados.push(...a.municipios)
            }
          })
          for (const i of data) {
            if (municipiosOcupados.length) {
              if (!municipiosOcupados.some(a => a.CodDane === i.CodDane)) {
                municipiosFiltrados.value.push(i)
              }
            } else municipiosFiltrados.value.push(i)
          }
        })
      }
      const filtrarMunicipios = (linea, name, eliminar) => {
        municipiosFiltrados.value = []
        const municipiosOcupados = []
        contratosStore.zonificaciones.filter(a => a.departamento.codDpto === linea.departamento.codDpto).map(a => {
          if (a.municipios.length) {
            municipiosOcupados.push(...a.municipios)
          }
        })
        for (const i of contratosStore._listado_municipios) {
          if (municipiosOcupados.length) {
            if (!municipiosOcupados.some(a => a.CodDane === i.CodDane)) {
              municipiosFiltrados.value.push(i)
            }
          } else municipiosFiltrados.value.push(i)
        }
        if (name) {
          if (eliminar) {
            linea.municipios_temp = linea.municipios.filter(a => a.nomMpio.toUpperCase().includes(name.toUpperCase()))
          } else {
            municipiosFiltrados.value = municipiosFiltrados.value.filter(a => a.nomMpio.toUpperCase().includes(name.toUpperCase()))
          }
        }
      }
      const agregarTodosMunicipios = (linea) => {
        linea.municipios.push(...municipiosFiltrados.value)
      }
      onMounted(() => {
        contratosStore.obtenerDepartamentos()
        contratosStore.obtenerRegimenes()
      })
      return {
        contratosStore,
        buscarZonificacion,
        agregarLinea,
        eliminarLinea,
        displayModalAggMunicipios,
        buscarMunicipios,
        eliminarMunicipio,
        municipiosFiltrados,
        filtrarMunicipios,
        abrirModalMunicipios,
        municipioBusqueda,
        municipioIngresadoBusqueda,
        agregarTodosMunicipios
      }
    }
  }
</script>
<style scoped>
  .border-input {
    padding: 0.45rem 0.75rem;
    border: 1px solid #ced4da;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
    margin: 1rem;
    padding: 1rem;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
</style>
